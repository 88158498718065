import Vue from "vue";
import Api from "@/modules/axios";
import i18n from "@/modules/i18n";

export default {
  state: {
    panier: null,
    isFetchingPanier: false,
  },
  getters: {
    panier(state) {
      return state.panier;
    },
    isFetchingPanier(state) {
      return state.isFetchingPanier;
    },
  },
  mutations: {
    setPanier(state, panier) {
      state.panier = panier;
      state.isFetchingPanier = false;
    },
    setIsFetchingPanier(state, bool) {
      state.isFetchingPanier = bool;
    },
  },
  actions: {
    /**
     * Met en place ou met à jour le panier pour l'utilisateur.
     * @param {Object} commit
     * @param {Object} getters
     * @returns {Promise} Panier de l'utilisateur.
     */
    async setPanier({ commit, getters }) {
      commit("setIsFetchingPanier", true);
      const params = {
        date_transfert__isnull: true,
        panier: true,
        utilisateur: getters.user.id,
        organisme: getters.organismeActuel.id,
      };

      return Api().get("/liste/", { params })
        .then(({ data }) => {
          if (data.results.length) { // L'utilisateur à au moins un panier
            const panierActuel = data.results[0];
            if (panierActuel) {
              // Si ce panier existe, on le définit comme actif
              commit("setPanier", panierActuel);
              return getters.panier;
            }
          }
          params.libelle = `${i18n.t("panier.mon-panier")} (${getters.user.id})`;
          return Api().post("/liste/", { ...params });
        })
        .then(({ data }) => {
          if (data) {
            commit("setPanier", data);
          }
          return getters.panier;
        })
        .catch((err) => {
          Vue.$toast.error({ title: err.response?.data || i18n.t("info.une-erreur-est-survenue") });
        });
    },
    /**
     * Met les offres dans le panier.
     * @param {Object} commit
     * @param {Object} getters
     * @param {Object} dispatch
     * @param {Array} lignes Offres à mettre dans le panier.
     * @returns {Promise}
     */
    addOffersToPanier({ commit, getters, dispatch }, { lignes = [] }) {
      commit("setIsFetchingPanier", true);
      const panierId = getters.panier.id;

      let msg = "";
      return Api().post(`/liste/${panierId}/add_lignes/`, { lignes })
        .then(({ data }) => {
          msg = data.already_in_list
            ? i18n.t("panier.offre-ajoutee-deja-dans-panier")
            : i18n.tc("panier.offre-bien-ajoutee-panier", lignes.length);

          const params = {
            date_transfert__isnull: true,
            page: 1,
            id: panierId,
          };
          return Api().get("/liste/", { params });
        })
        .then(() => {
          dispatch("setPanier");
          Vue.$toast.success({ title: msg });
        })
        .catch((err) => {
          Vue.$toast.error({ title: err.response?.data || i18n.t("info.une-erreur-est-survenue") });
        });
    },
    /**
     * Ajoute les lignes de commande au panier.
     * @param {Object} commit
     * @param {Object} getters
     * @param {Object} dispatch
     * @param {Array} lignes Lignes de commande/Offres à mettre dans le panier.
     * @returns {Promise}
     */
    addOrderLinesToPanier({ commit, getters, dispatch }, { commandes = [] }) {
      commit("setIsFetchingPanier", true);
      const panierId = getters.panier.id;

      return Api().post(`/liste/${panierId}/copy_order/`, { commandes })
        .then(({ data }) => {
          if (data.code === "Aucune") {
            Vue.$toast.error({ title: i18n.t("panier.tous-les-produits-deja-dans-panier") });
          } else {
            if (data.code === "Min une") {
              Vue.$toast.warning({ title: i18n.t("panier.toutes-offres-pas-mesure-ajoutees-panier") });
            }
            Vue.$toast.success({
              title: i18n.tc("panier.reference-bien-ajoutee-panier", 2),
            });
          }
        })
        .catch((error) => {
          Vue.$toast.error({ title: error.response?.data || i18n.t("info.une-erreur-est-survenue") });
        })
        .finally(() => {
          dispatch("setPanier");
        });
    },
  },
};
